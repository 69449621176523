import request from '@/utils/request';

const PATH = '/v1/brand';

// 1.	基本信息
export function getBaseInfo(params = {}) {
  return request({
    url: PATH + '/info',
    method: 'GET',
    params,
  });
}

// 品牌故事-产品特点
export function getAdvantage(params = {}) {
  return request({
    url: PATH + '/advantage',
    method: 'GET',
    params,
  });
}

// 2.	特色菜品
export function getFood(params = {}) {
  return request({
    url: PATH + '/food',
    method: 'GET',
    params,
  });
}

// 4.	门店环境
export function getEnv(params = {}) {
  return request({
    url: PATH + '/group',
    method: 'GET',
    params,
  });
}

// 5.	全国门店区域
export function getRegion(params = {}) {
  return request({
    url: PATH + '/region',
    method: 'GET',
    params,
  });
}

// 门店环境详情
export function getEnvDetail(params = {}) {
  return request({
    url: PATH + '/store',
    method: 'POST',
    params,
  });
}

// 6.	全国门店
export function getRegionStore(params = {}) {
  return request({
    url: PATH + '/searchRegion',
    method: 'POST',
    params,
  });
}

// 3.特色菜品详情
export function getFoodDetail(params = {}) {
  return request({
    url: PATH + '/food_detail',
    method: 'POST',
    params,
  });
}
