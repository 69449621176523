<template>
  <div class="shopItem">
    <p class="title">{{ data.title }}</p>
    <div class="box">
      <div
        class="addr"
        @click="handleClick"
      >
        <span class="base-icon-dizhi base-iconfont"></span>
        <span class="text">{{ data.addr }}</span>
      </div>
      <div class="tel">
        <a
          :href="`tel:${data.tel}`"
          class="text no-gesture"
        ><span class="base-icon-dianhua2 base-iconfont"></span>{{ data.tel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClick() {
      window.open(this.data.url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.shopItem {
  position: relative;
  min-height: 230px;
  padding: 35px 0;
  &::after,
  &::before {
    content: '';
    height: 1px;
    background: $border-color-5;
    left: 0;
    right: 0;
    position: absolute;
  }
  &::after {
    bottom: 0;
  }
  &::before {
    top: -1px;
  }
  .title {
    @include text-nowrap;
    font-size: 24px;
    line-height: math.div(32, 24);
  }
  .box {
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
    .base-iconfont {
      display: block;
      font-size: 24px;
      line-height: 1;
      color: $sub-color-3;
      margin-bottom: 19px;
    }
    .text {
      font-size: 16px;
      line-height: math.div(24, 16);
      color: $text-color-3;
    }
  }
  .addr {
    width: math.percentage(math.div(294, 560));
    cursor: pointer;
    @media screen and (min-width: $hover-point) {
      .text,
      .base-iconfont {
        transition: 300ms;
      }
      &:hover {
        .text,
        .base-iconfont {
          color: $sub-color-2;
        }
      }
    }
  }
  .tel {
    width: math.percentage(math.div(212, 560));
  }
  @media screen and (max-width: $res-point-7) {
    min-height: auto;
  }
  @media screen and (max-width: $res-point-10) {
    background: #fff;
    box-shadow: $card-shadow-1;
    padding: 25px 15px;
    border-radius: 6px;
    &::before,
    &::after {
      display: none;
    }
    .title {
      font-size: 24px * 0.8;
      color: $text-color-2;
    }
    .box {
      padding-top: 38px * 0.8;
      .base-iconfont {
        font-size: 24px * 0.8;
        margin-bottom: 19px * 0.8;
      }
      .text {
        font-size: 14px;
      }
    }
  }
}
</style>