<template>
  <div class="search">
    <el-popover
      placement="bottom-end"
      width="240"
      trigger="click"
    >
      <el-input
        placeholder="请输入内容"
        v-model="input"
        class="input"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="onSearch"
        ></el-button>
      </el-input>
      <div
        slot="reference"
        class="base-icon-sousuo base-iconfont"
      ></div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
    };
  },
  methods: {
    onSearch() {
      this.$emit('onSearch', this.input);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.search {
  line-height: 1;
  .base-iconfont {
    cursor: pointer;
    font-size: 24px;
    color: $text-font-5;
  }
  ::v-deep {
    .el-popover {
      padding: 5px;
    }
  }
  @media screen and (max-width: $res-point-10) {
    .base-iconfont {
      font-size: 14px;
    }
  }
}
.input {
  &::v-deep {
    &.el-input.is-active .el-input__inner {
      border-color: $sub-color-2;
    }
  }
  ::v-deep {
    .el-input__inner:focus {
      border-color: $sub-color-2;
    }
  }
}
</style>