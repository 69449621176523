<template>
  <el-tabs
    type="border-card"
    class="nav"
    v-model="activeName"
    @tab-click="handleClick"
  >
    <el-tab-pane
      v-for="item in options"
      :key="item.value"
      :label="item.text"
      :name="item.value"
    />
  </el-tabs>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeName: '',
    };
  },
  mounted() {},
  methods: {
    handleClick() {
      this.$emit('onClick', this.activeName);
    },
  },
  watch: {
    defaultValue() {
      this.activeName = this.defaultValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.nav {
  &::v-deep {
    &.el-tabs--border-card {
      background: none;
      border: none;
      box-shadow: none;
      .el-tabs__content {
        display: none;
      }
      .el-tabs__header {
        border: none;
        background: none;
        .el-tabs__nav-wrap {
          margin: 0;
        }
        .el-tabs__item {
          padding: 0 0 0 14px;
          height: 20px;
          line-height: 20px;
          color: $text-font-5;
          font-size: 16px;
          font-weight: normal;
          border: none;
          margin: 0;
          &.is-active {
            background: none;
            color: $sub-color-2;
            &::before {
              background: $sub-color-2;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &::before {
            content: '';
            width: 2px;
            position: absolute;
            height: 12px;
            background: $text-font-5;
            left: 0;
            top: 50%;
            margin-top: -6px;
            transition: 300ms;
          }
          + .el-tabs__item {
            margin-left: 75px;
          }
        }
        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          line-height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-10) {
    &::v-deep {
      &.el-tabs--border-card {
        .el-tabs__header {
          .el-tabs__item {
            font-size: 14px;
            padding: 0;
            &::before {
              display: none;
            }
            + .el-tabs__item {
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $res-point-9) {
    &::v-deep {
      &.el-tabs--border-card {
        .el-tabs__header {
          .el-tabs__item {
            + .el-tabs__item {
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
}
</style>