<template>
  <div class="content">
    <div class="filterBox">
      <ShopNav
        class="shopNav"
        :options="options"
        @onClick="changeNav"
        :defaultValue="currentRegion"
      />
      <DropDown
        class="search"
        @onSearch="onSearch"
      />
    </div>
    <div
      class="currentSearch"
      v-if="searchText"
    >当前搜索结果：<span class="searchText">{{ searchText }}</span><span
        @click="onClear"
        class="clear"
      >清空 ></span></div>
    <div v-loading="loading">
      <div
        class="list"
        v-if="listData.length"
      >
        <ShopItem
          v-for="item in listData"
          :data="item"
          :key="item.id"
          class="item"
        />
      </div>
      <el-empty v-else />
    </div>
  </div>
</template>

<script>
import ShopItem from '../components/Card/Shop.vue';
import { ShopNav } from '../components/Nav';
import { DropDown } from '@/components/Search';
import { getRegion, getRegionStore } from '@/api/ShuJiuXiang/brand';
export default {
  components: {
    ShopItem,
    ShopNav,
    DropDown,
  },
  data() {
    return {
      listData: [],
      options: [],
      loading: false,
      currentRegion: '',
      searchText: '',
    };
  },
  mounted() {
    this.getRegion();
    this.getData();
  },
  methods: {
    onSearch(val) {
      this.searchText = val;
      this.currentRegion = '';
      this.getData();
    },
    onClear() {
      this.onSearch('');
    },
    changeNav(val) {
      this.currentRegion = val;
      this.getData();
    },
    async getRegion() {
      const res = await getRegion();
      this.options = res.map((d) => ({ text: d.regionName, value: d.regionId + '' }));
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getRegionStore({
          regionId: this.currentRegion,
          wholeName: this.searchText,
        });
        this.loading = false;
        this.listData = res.map((d) => ({
          id: d.wholeId,
          title: d.wholeName,
          addr: d.wholeAddress,
          tel: d.wholeTel,
          url: d.wholeUri,
        }));
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.content {
  overflow: hidden;
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  padding: math.percentage(math.div(158 * 0.8, 1920)) 0 math.percentage(math.div(200 * 0.8, 1920));
}
.searchText {
  color: $sub-color-2;
  margin-right: 15px;
}
.currentSearch {
  font-size: 16px;
  line-height: 24px;
  .clear {
    color: #aaa;
    font-size: 14px;
    cursor: pointer;
    @media screen and (min-width: $hover-point) {
      &:hover {
        color: $sub-color-2;
      }
    }
  }
}
.list {
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: math.percentage(math.div(560, 1200));
  }
}
.filterBox {
  padding-bottom: 38px;
  display: flex;
  align-items: center;
  .shopNav {
    flex: 1;
    overflow: hidden;
  }
  .search {
    margin-left: 20px;
  }
}
@media screen and (max-width: $res-point-7) {
  .list {
    .item {
      width: 100%;
    }
  }
}
@media screen and (max-width: $res-point-10) {
  .list {
    .item + .item {
      margin-top: 15px;
    }
  }
}
</style>